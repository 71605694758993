.image-style {
  width: 16rem;
  border-width: 10px;
  border-color: #e0cedf;
  object-fit: cover;
  animation: profile-animate 6s linear 1s infinite;
}

.move-style {
  width: 6rem;
  animation-name: confetti;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  user-select: none;
  pointer-events: none;
  z-index: 1;
}

@keyframes confetti {
  0% {
    transform: translate(0px, 2px);
  }

  100% {
    transform: translate(500px, 700px);
  }
}

@keyframes profile-animate {
  0% {
    border-top-right-radius: 0rem;
    border-bottom-right-radius: 0rem;
    border-bottom-left-radius: 0rem;
    border-top-left-radius: 0rem;
  }
  10% {
    border-top-right-radius: 4rem;
    border-bottom-right-radius: 18rem;
    border-bottom-left-radius: 4rem;
    border-top-left-radius: 18rem;
  }
  20% {
    border-top-right-radius: 8rem;
    border-bottom-right-radius: 16rem;
    border-bottom-left-radius: 8rem;
    border-top-left-radius: 16rem;
  }
  30% {
    border-top-right-radius: 12rem;
    border-bottom-right-radius: 14rem;
    border-bottom-left-radius: 12rem;
    border-top-left-radius: 14rem;
  }
  40% {
    border-top-right-radius: 8rem;
    border-bottom-right-radius: 18rem;
    border-bottom-left-radius: 8rem;
    border-top-left-radius: 18rem;
  }
  50% {
    border-top-right-radius: 12rem;
    border-bottom-right-radius: 14rem;
    border-bottom-left-radius: 12rem;
    border-top-left-radius: 14rem;
  }
  60% {
    border-top-right-radius: 14rem;
    border-bottom-right-radius: 8rem;
    border-bottom-left-radius: 14rem;
    border-top-left-radius: 8rem;
  }
  70% {
    border-top-right-radius: 16rem;
    border-bottom-right-radius: 12rem;
    border-bottom-left-radius: 16rem;
    border-top-left-radius: 12rem;
  }
  80% {
    border-top-right-radius: 8rem;
    border-bottom-right-radius: 10rem;
    border-bottom-left-radius: 8rem;
    border-top-left-radius: 10rem;
  }
  90% {
    border-top-right-radius: 4rem;
    border-bottom-right-radius: 6rem;
    border-bottom-left-radius: 4rem;
    border-top-left-radius: 6rem;
  }
  100% {
    border-top-right-radius: 0rem;
    border-bottom-right-radius: 0rem;
    border-bottom-left-radius: 0rem;
    border-top-left-radius: 0rem;
  }
}

.hamburger {
  cursor: pointer;
  width: 24px;
  height: 24px;
  transition: all 0.25s;
  position: relative;
}

.hamburger-top,
.hamburger-middle,
.hamburger-bottom {
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 2px;
  background: #000;
  transform: rotate(0);
  transition: all 0.5s;
}

.hamburger-middle {
  transform: translateY(7px);
}
.hamburger-bottom {
  transform: translateY(14px);
}

.open {
  transform: rotate(90deg);
  transform: translateY(0px);
}

.open .hamburger-top {
  transform: rotate(45deg) translateY(6px) translate(6px);
}

.open .hamburger-middle {
  display: none;
}
.open .hamburger-bottom {
  transform: rotate(-45deg) translateY(6px) translate(-6px);
}
